<template>
    <page-content>
        <template v-slot:summary>
            Front-end web development.  Back-end services development.  We've got you covered.
        </template>
        
        <template v-slot:watermark>
            <services-icon />
        </template>

        <p>
             With 20 years of web and server-side development experience, LucidQS has the knowledge and expertise
             you can rely on to get your project completed; on time, and on budget.
        </p>

        <p>
            We are ready to help you with any and all aspects of your software development project from initial 
            analysis and design through to development and implementation.
        </p>

        <p>
            Experience with traditional software development practices as well as modern Agile frameworks help us
            fit into any style development team you may have.
        </p>

        <div class="skills">
            <div class="heading">Our core competencies include:</div>
            <div class="skill">Java</div>
            <div class="skill">Spring Framework</div>
            <div class="skill">Spring Boot</div>
            <div class="skill">Microservices</div>
            <div class="skill">Python</div>
            <div class="skill">Django</div>
            <div class="skill">React</div>
            <div class="skill">Vue.js</div>
            <div class="skill">Docker</div>
            <div class="skill">JavaScript</div>
            <div class="skill">HTML</div>
            <div class="skill">XML</div>
            <div class="skill">JSON</div>
            <div class="skill">CSS3</div>
            <div class="skill">SCSS</div>
            <div class="skill">UML</div>
        </div>

        <div>
            <router-link to="contact">Contact us now</router-link> to find out more about how we can help you.
        </div>
    </page-content>
</template>

<script>
    import Content from '@/components/Content';
    import ServicesIcon from '@/assets/services.svg';

    export default {
        components: {
            'page-content': Content,
            'services-icon': ServicesIcon
        }    
    }
</script>

<style scoped lang="scss">
    .skills {
        margin: 3em 0;

        .heading {
            margin-bottom: 2em;
        }

        .skill {
            display: inline-block;
            margin-right: 2em;
            margin-bottom: 1em;
            font-weight: bold;
            font-size: 1.25em;
            color: color('primary');
        }
    }

    a {
        color: white;
    }
</style>
